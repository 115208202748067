import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";

import {
  savePatientImmigration,
  getImmigrationFormKeys,
} from "actions/front/Staff/Patient";
import Alert from "views/Notifications/Alert";
import { getImmigrationFieldValue } from "utils/immgrationHelper";
import { extractNameto3 } from "utils/nodeHelper";
import moment from "moment";
import { statesUs } from "constants/statesUs";

import { setAlert } from "actions/alert";

const SelectLanguageModal = ({ formData, setFormData, onClickChoose }) => {
  const handleSelect = () => (selectedOption) => {
    setFormData({ ...formData, language: selectedOption.value });
  };
  const { language } = formData;

  return (
    <Modal isOpen={language === ""}>
      <ModalHeader>
        <div className="d-inline">Please select language</div>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="language">
            Language <span>*</span>
          </Label>
          <Select
            id="language"
            name="language"
            options={[
              {
                value: "en",
                label: "English",
              },
              {
                value: "es",
                label: "Spanish",
              },
            ]}
            onChange={handleSelect()}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          size="sm"
          onClick={() => {
            onClickChoose();
          }}
        >
          Choose
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ImmgrationForm = ({
  currentEmployee,
  savePatientImmigration,
  getImmigrationFormKeys,
  history,
  currentImmigration,
}) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [formUpdated, setFormUpdated] = React.useState(false);

  // State to store form values
  const [formData, setFormData] = useState({
    language: "",
    physicianName: "",
    familyName: "",
    givenName: "",
    middleName: "",
    physicianAddress: "",
    physicianApartment: false,
    physicianStreet: false,
    physicianFloor: false,
    physicianNumber: "",
    physicianCity: "",
    physicianState: "",
    physicianZipCode: "",
    physicianProvince: "",
    physicianPostalCode: "",
    physicianCountry: "",
    gender: "",
    dob: "",
    cityOfBirth: "",
    countryOfBirth: "",
    alienNumber: "",
    uscisNumber: "",
    vaccinationRecord: false,
    aNumber: "",
    daytimePhone: "",
    mobilePhone: "",
    email: "",
    signatureDate: moment().format("MM/DD/YYYY"),
    interpreterFamilyName: "",
    interpreterGivenName: "",
    interpreterBusinessName: "",
    interpreterDaytimePhone: "",
    interpreterMobilePhone: "",
    interpreterEmail: "",
    additionalInformation: [
      {
        page_number: "",
        part_number: "",
        item_number: "",
        description: "",
      },
      {
        page_number: "",
        part_number: "",
        item_number: "",
        description: "",
      },
      {
        page_number: "",
        part_number: "",
        item_number: "",
        description: "",
      },
    ],
  });

  React.useEffect(() => {
    if (!currentImmigration) return;

    const {
      language,
      physicianName,
      physicianAddress,
      physicianApartment,
      physicianStreet,
      physicianFloor,
      physicianNumber,
      physicianCity,
      physicianState,
      physicianZipCode,
      physicianProvince,
      physicianPostalCode,
      physicianCountry,
      cityOfBirth,
      countryOfBirth,
      alienNumber,
      uscisNumber,
      vaccinationRecord,
      aNumber,
      daytimePhone,
      mobilePhone,
      interpreterFamilyName,
      interpreterGivenName,
      interpreterBusinessName,
      interpreterDaytimePhone,
      interpreterMobilePhone,
      interpreterEmail,
      additionalInformation,
    } = currentImmigration;

    const data = {
      language,
      physicianName,
      physicianAddress,
      physicianApartment,
      physicianStreet,
      physicianFloor,
      physicianNumber,
      physicianCity,
      physicianState,
      physicianZipCode,
      physicianProvince,
      physicianPostalCode,
      physicianCountry,
      cityOfBirth,
      countryOfBirth,
      alienNumber,
      uscisNumber,
      vaccinationRecord,
      aNumber,
      daytimePhone,
      mobilePhone,
      interpreterFamilyName,
      interpreterGivenName,
      interpreterBusinessName,
      interpreterDaytimePhone,
      interpreterMobilePhone,
      interpreterEmail,
      additionalInformation,
    };

    setFormData({ ...formData, ...data });
  }, [currentImmigration]);

  const {
    language,
    familyName,
    givenName,
    middleName,
    physicianName,
    physicianAddress,
    physicianApartment,
    physicianStreet,
    physicianFloor,
    physicianNumber,
    physicianCity,
    physicianState,
    physicianZipCode,
    physicianProvince,
    physicianPostalCode,
    physicianCountry,
    gender,
    dob,
    cityOfBirth,
    countryOfBirth,
    alienNumber,
    uscisNumber,
    vaccinationRecord,
    aNumber,
    daytimePhone,
    mobilePhone,
    email,
    signatureDate,
    interpreterFamilyName,
    interpreterGivenName,
    interpreterBusinessName,
    interpreterDaytimePhone,
    interpreterMobilePhone,
    interpreterEmail,
    additionalInformation,
  } = formData;

  const onClickChoose = () => {
    if (!language) {
      dispatch(setAlert("Please select language", "danger"));
      return;
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSaving(true);

    savePatientImmigration(
      currentEmployee._id,
      currentImmigration?._id,
      formData,
      history
    ).then((res) => {
      setSaving(false);
      if (res?.status) {
        setFormUpdated(true);
      }
    });
  };

  const onChangeAdditionalInformation = (e, index) => {
    const { name, value } = e.target;

    const selectedAdditionalInformation = additionalInformation[index];

    selectedAdditionalInformation[name] = value;

    const newAdditionalInformation = additionalInformation.map((item, i) => {
      if (i === index) {
        return selectedAdditionalInformation;
      }
      return item;
    });

    setFormData({
      ...formData,
      additionalInformation: newAdditionalInformation,
    });
  };

  React.useEffect(() => {
    if (!language || !currentEmployee) return;
    setLoading(true);

    getImmigrationFormKeys(currentEmployee._id, language).then((res) => {
      setLoading(false);
    });
  }, [language]);

  React.useEffect(() => {
    if (!currentEmployee) return;

    const { firstName, lastName, middleName } = extractNameto3(
      currentEmployee?.user?.name
    );

    setFormData((form) => ({
      ...form,
      familyName: lastName,
      givenName: firstName,
      middleName: middleName,
      gender: currentEmployee?.user.gender,
      dob: moment(currentEmployee?.user?.dob).format("YYYY-MM-DD"),
      email: currentEmployee?.user.email,
    }));
  }, [currentEmployee]);

  const handleClose = () => {
    window.close();
  };

  return !language ? (
    <SelectLanguageModal
      onClickChoose={onClickChoose}
      formData={formData}
      setFormData={setFormData}
      history={history}
    />
  ) : (
    <div>
      {formUpdated ? (
        <div>
          <Alert />

          <div className="d-flex justify-content-center">
            <Button onClick={handleClose}>Close Tab</Button>
          </div>
        </div>
      ) : (
        <Row>
          <Col sm="12">
            <div className="page-header d-flex justify-content-center">
              <div className="round-button-position">
                <span className="h4">Complete Patient Immigration</span>
              </div>
            </div>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <div className="immgration-form-header mb-3 p-3">
                    <div
                      className="text-large"
                      dangerouslySetInnerHTML={{
                        __html: getImmigrationFieldValue(
                          "part_1_information_about_you",
                          language
                        ),
                      }}
                    ></div>
                  </div>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={12}>
                          1.
                          <span className="pl-3">
                            {getImmigrationFieldValue(
                              "full_legal_name",
                              language
                            )}
                            {getImmigrationFieldValue("do_not", language)}
                            {getImmigrationFieldValue(
                              "provide_nickname",
                              language
                            )}
                          </span>
                        </Col>

                        <Col className="pl-5" sm={12}>
                          <Row>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="familyName">
                                  {getImmigrationFieldValue(
                                    "family_name",
                                    language
                                  )}
                                </Label>
                                <Input
                                  type="text"
                                  id="familyName"
                                  name="familyName"
                                  value={familyName}
                                  onChange={handleChange}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="givenName">
                                  {getImmigrationFieldValue(
                                    "given_name",
                                    language
                                  )}
                                </Label>
                                <Input
                                  type="text"
                                  id="givenName"
                                  name="givenName"
                                  value={givenName}
                                  onChange={handleChange}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="middleName">
                                  {getImmigrationFieldValue(
                                    "middle_name_if_applicable",
                                    language
                                  )}
                                </Label>
                                <Input
                                  type="text"
                                  id="middleName"
                                  name="middleName"
                                  value={middleName}
                                  onChange={handleChange}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      2.
                      <span className="pl-3">
                        {getImmigrationFieldValue(
                          "current__physician_address",
                          language
                        )}
                      </span>
                    </Col>

                    <Col className="pl-5" sm={12}>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="physicianName">
                              {getImmigrationFieldValue("care_of", language)}
                            </Label>
                            <Input
                              type="text"
                              id="physicianName"
                              name="physicianName"
                              value={physicianName}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="physicianAddress">
                              {getImmigrationFieldValue(
                                "street_address",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              id="physicianAddress"
                              name="physicianAddress"
                              value={physicianAddress}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={1} className="d-flex align-items-center">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="physicianApartment"
                                name="physicianApartment"
                                checked={physicianApartment}
                                onChange={handleChange}
                              />
                              {getImmigrationFieldValue("apt", language)}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md={1} className="d-flex align-items-center">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="physicianStreet"
                                name="physicianStreet"
                                checked={physicianStreet}
                                onChange={handleChange}
                              />
                              {getImmigrationFieldValue("ste", language)}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md={1} className="d-flex align-items-center">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="physicianFloor"
                                name="physicianFloor"
                                checked={physicianFloor}
                                onChange={handleChange}
                              />
                              {getImmigrationFieldValue("flr", language)}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label for="physicianNumber">
                              {getImmigrationFieldValue("number", language)}
                            </Label>
                            <Input
                              type="text"
                              id="physicianNumber"
                              name="physicianNumber"
                              value={physicianNumber}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="physicianCity">
                              {getImmigrationFieldValue("city", language)}
                            </Label>
                            <Input
                              type="text"
                              id="physicianCity"
                              name="physicianCity"
                              value={physicianCity}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label for="physicianState">
                              {getImmigrationFieldValue("state", language)}
                            </Label>
                            <Input
                              type="select"
                              id="physicianState"
                              name="physicianState"
                              value={physicianState}
                              onChange={handleChange}
                            >
                              <option value="">
                                {getImmigrationFieldValue("choose", language)}
                                ...
                              </option>

                              {statesUs.map((state) => {
                                return <option value={state}>{state}</option>;
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label for="physicianZipCode">
                              {getImmigrationFieldValue("zip_code", language)}
                            </Label>
                            <Input
                              type="text"
                              id="physicianZipCode"
                              name="physicianZipCode"
                              value={physicianZipCode}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="physicianProvince">
                              {getImmigrationFieldValue("province", language)}
                            </Label>
                            <Input
                              type="text"
                              id="physicianProvince"
                              name="physicianProvince"
                              value={physicianProvince}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="physicianPostalCode">
                              {getImmigrationFieldValue(
                                "postal_code",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              id="physicianPostalCode"
                              name="physicianPostalCode"
                              value={physicianPostalCode}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="physicianCountry">
                              {getImmigrationFieldValue("country", language)}
                            </Label>
                            <Input
                              type="text"
                              id="physicianCountry"
                              name="physicianCountry"
                              value={physicianCountry}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      3.
                      <span className="ml-2">
                        {getImmigrationFieldValue(
                          "other_information",
                          language
                        )}
                      </span>
                    </Col>
                    <Col className="pl-5" sm={12}>
                      <Row>
                        <Col md={2}>
                          <FormGroup>
                            <Label for="gender">
                              {getImmigrationFieldValue("gender", language)}
                            </Label>
                            <Input
                              type="select"
                              id="gender"
                              name="gender"
                              value={gender}
                            >
                              <option value="m" disabled>
                                {getImmigrationFieldValue("male", language)}
                              </option>
                              <option value="f" disabled>
                                {getImmigrationFieldValue("female", language)}
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label for="dob">
                              {getImmigrationFieldValue(
                                "date_of_birth",
                                language
                              )}
                            </Label>
                            <Input
                              type="date"
                              id="dob"
                              name="dob"
                              value={dob}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="cityOfBirth">
                              {getImmigrationFieldValue(
                                "city_of_birth",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              id="cityOfBirth"
                              name="cityOfBirth"
                              value={cityOfBirth}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          <FormGroup>
                            <Label for="countryOfBirth">
                              {getImmigrationFieldValue(
                                "country_of_birth",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              id="countryOfBirth"
                              name="countryOfBirth"
                              value={countryOfBirth}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="alienNumber">
                              {getImmigrationFieldValue(
                                "alien_registration",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              id="alienNumber"
                              name="alienNumber"
                              value={alienNumber}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="uscisNumber">
                              {getImmigrationFieldValue(
                                "uscis_account_number",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              id="uscisNumber"
                              name="uscisNumber"
                              value={uscisNumber}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      4.
                      <span className="ml-3">
                        {getImmigrationFieldValue(
                          "vaccination_record",
                          language
                        )}
                      </span>
                    </Col>
                    <Col className="ml-5" sm={12}>
                      <Row>
                        <Col md={12}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="vaccinationRecord"
                                name="vaccinationRecord"
                                checked={vaccinationRecord}
                                onChange={handleChange}
                              />
                              {getImmigrationFieldValue(
                                "vaccination_option",
                                language
                              )}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="mt-2">
                          <strong>
                            {getImmigrationFieldValue("note", language)}
                          </strong>
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={12} className="mt-3">
                      <Table bordered>
                        <thead>
                          <tr>
                            <th className="text-center">
                              {getImmigrationFieldValue(
                                "family_name",
                                language
                              )}
                            </th>
                            <th className="text-center">
                              {getImmigrationFieldValue("given_name", language)}
                            </th>
                            <th className="text-center">
                              {getImmigrationFieldValue(
                                "middle_name",
                                language
                              )}
                            </th>
                            <th className="text-center">
                              {getImmigrationFieldValue(
                                "a_number_if_any",
                                language
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">{familyName}</td>
                            <td className="text-center">{givenName}</td>
                            <td className="text-center">{middleName}</td>
                            <td>
                              <Input
                                type="text"
                                name="aNumber"
                                value={aNumber}
                                onChange={handleChange}
                                maxLength="9"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <div className="immgration-form-header mb-3 p-3">
                    <h4 className="">
                      {getImmigrationFieldValue("part2_statement", language)}
                    </h4>
                  </div>
                  <Row>
                    <Col sm={12}>
                      <div className="immgration-form-sub-header">
                        {getImmigrationFieldValue("contact_info", language)}
                      </div>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="daytimePhone">
                          1.{" "}
                          {getImmigrationFieldValue(
                            "daytime_telephone",
                            language
                          )}
                        </Label>
                        <Input
                          className="pl-3"
                          type="text"
                          id="daytimePhone"
                          name="daytimePhone"
                          value={daytimePhone}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="mobilePhone">
                          {getImmigrationFieldValue(
                            "mobile_telephone",
                            language
                          )}
                        </Label>
                        <Input
                          className="pl-3"
                          type="text"
                          id="mobilePhone"
                          name="mobilePhone"
                          value={mobilePhone}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="email">
                          {getImmigrationFieldValue("email_address", language)}
                        </Label>
                        <Input
                          className="pl-3"
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12" className="mb-1 p-3">
                      <div className="immgration-form-sub-header">
                        {getImmigrationFieldValue(
                          "certification_signature",
                          language
                        )}
                      </div>

                      <p>
                        {getImmigrationFieldValue(
                          "certification_text",
                          language
                        )}
                      </p>
                    </Col>

                    <Col sm={12} className="mt-1">
                      <strong>
                        {getImmigrationFieldValue("do_not_sign", language)}
                      </strong>
                    </Col>

                    <Col md={8}>
                      <FormGroup>
                        <Label for="signature" className="d-block mb-2">
                          {getImmigrationFieldValue("signature", language)}
                        </Label>
                        <Input
                          type="text"
                          id="signature"
                          name="signature"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="signatureDate">
                          {getImmigrationFieldValue("date_signature", language)}
                        </Label>
                        <Input
                          type="text"
                          id="signatureDate"
                          name="signatureDate"
                          value={signatureDate}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div sm="12" className="immgration-form-header mb-3 p-3">
                    <h4 className="m-0 p-0">
                      {getImmigrationFieldValue("part3_statement", language)}
                    </h4>
                  </div>

                  <Row>
                    <Col sm="12" className="p-3">
                      <div className="immgration-form-sub-header">
                        {getImmigrationFieldValue("interpreter_name", language)}
                      </div>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="interpreterFamilyName">
                          1.{" "}
                          {getImmigrationFieldValue(
                            "interpreter_family_name",
                            language
                          )}
                        </Label>
                        <Input
                          className="ml-3"
                          type="text"
                          id="interpreterFamilyName"
                          name="interpreterFamilyName"
                          value={interpreterFamilyName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="interpreterGivenName">
                          {getImmigrationFieldValue(
                            "interpreter_given_name",
                            language
                          )}
                        </Label>
                        <Input
                          type="text"
                          id="interpreterGivenName"
                          name="interpreterGivenName"
                          value={interpreterGivenName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="interpreterBusinessName">
                          2.{" "}
                          {getImmigrationFieldValue(
                            "interpreter_business_name",
                            language
                          )}
                        </Label>
                        <Input
                          className="ml-3"
                          type="text"
                          id="interpreterBusinessName"
                          name="interpreterBusinessName"
                          value={interpreterBusinessName}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12" className="p-3">
                      <div className="immgration-form-sub-header">
                        {getImmigrationFieldValue(
                          "interpreter_contact_info",
                          language
                        )}
                      </div>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="interpreterDaytimePhone">
                          3.{" "}
                          {getImmigrationFieldValue(
                            "interpreter_daytime_phone",
                            language
                          )}
                        </Label>
                        <Input
                          className="ml-3"
                          type="text"
                          id="interpreterDaytimePhone"
                          name="interpreterDaytimePhone"
                          value={interpreterDaytimePhone}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        4.{" "}
                        <Label for="interpreterMobilePhone">
                          {getImmigrationFieldValue(
                            "interpreter_mobile_phone",
                            language
                          )}
                        </Label>
                        <Input
                          className="ml-3"
                          type="text"
                          id="interpreterMobilePhone"
                          name="interpreterMobilePhone"
                          value={interpreterMobilePhone}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        5.{" "}
                        <Label for="interpreterEmail">
                          {getImmigrationFieldValue(
                            "interpreter_email",
                            language
                          )}
                        </Label>
                        <Input
                          className="ml-3"
                          type="text"
                          id="interpreterEmail"
                          name="interpreterEmail"
                          value={interpreterEmail}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="immgration-form-header mb-3 p-3">
                    <h4 className="m-0 p-0">
                      {getImmigrationFieldValue(
                        "part_11_additional_information",
                        language
                      )}
                    </h4>
                  </div>
                  <Row>
                    <Col sm="12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getImmigrationFieldValue(
                            "part_11_description",
                            language
                          ),
                        }}
                        className="my-2"
                      ></div>
                    </Col>

                    <Col sm={12}>
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="familyName">
                              1.{" "}
                              {getImmigrationFieldValue(
                                "family_name",
                                language
                              )}
                            </Label>
                            <Input
                              className="ml-3"
                              type="text"
                              name="familyName"
                              value={familyName}
                              disabled
                            />
                          </FormGroup>
                        </Col>

                        <Col md={4}>
                          <FormGroup>
                            <Label for="givenName">
                              {getImmigrationFieldValue("given_name", language)}
                            </Label>
                            <Input
                              type="text"
                              name="givenName"
                              disabled
                              value={givenName}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={4}>
                          <FormGroup>
                            <Label for="middleName">
                              {getImmigrationFieldValue(
                                "middle_name",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              name="middleName"
                              disabled
                              value={middleName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={6}>
                      <Row>
                        <Col>
                          <FormGroup className="d-flex">
                            <Label for="aNumber" className="w-25">
                              2.{" "}
                              {getImmigrationFieldValue(
                                "a_number_if_any",
                                language
                              )}
                            </Label>
                            <Input
                              type="text"
                              name="aNumber"
                              id="aNumber"
                              value={aNumber}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={12}>
                      {[3, 4, 5].map((sectionNumber, i) => (
                        <FormGroup key={sectionNumber}>
                          <Row>
                            <Col md={2}>
                              <Label>
                                {sectionNumber} A.{" "}
                                {getImmigrationFieldValue(
                                  "page_number_a",
                                  language
                                )}
                              </Label>
                              <Input
                                className="ml-3"
                                type="text"
                                name="page_number"
                                value={additionalInformation[i]?.page_number}
                                onChange={(e) =>
                                  onChangeAdditionalInformation(e, i)
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <Label>
                                B.{" "}
                                {getImmigrationFieldValue(
                                  "part_number_b",
                                  language
                                )}
                              </Label>
                              <Input
                                className="ml-3"
                                type="text"
                                name="part_number"
                                value={additionalInformation[i]?.part_number}
                                onChange={(e) =>
                                  onChangeAdditionalInformation(e, i)
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <Label>
                                C.{" "}
                                {getImmigrationFieldValue(
                                  "item_number_c",
                                  language
                                )}
                              </Label>
                              <Input
                                className="ml-3"
                                type="text"
                                name="item_number"
                                value={additionalInformation[i]?.item_number}
                                onChange={(e) =>
                                  onChangeAdditionalInformation(e, i)
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <Label for={`additionalInfo${sectionNumber}`}>
                                D.
                              </Label>
                              <Input
                                type="textarea"
                                name="description"
                                value={additionalInformation[i]?.description}
                                onChange={(e) =>
                                  onChangeAdditionalInformation(e, i)
                                }
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      ))}
                    </Col>
                  </Row>

                  <br />
                  <div className="d-flex float-end">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-2"
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  currentImmigration: state.staffOrderPatient.currentImmigration,
});

export default connect(mapStateToProps, {
  savePatientImmigration,
  getImmigrationFormKeys,
})(ImmgrationForm);
