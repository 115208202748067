import React from "react";
import { connect } from "react-redux";
import Img from "react-image";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import "react-phone-number-input/style.css";
import { Helmet } from "react-helmet";

import { getEmployeeById } from "actions/front/Employer/Employee";
import { loadPage, notFound } from "actions/front/Employer/EmployerProfile";
import { loadAuthPage } from "actions/auth";
import { removeAlert } from "actions/alert";

import { getPatientImmigrationData } from "actions/front/Staff/Patient";

import InActiveLink from "views/InActiveLink";
import LogoSvg from "assets/iLabDx-Logo.png";
import Spinner from "views/Spinner";

import ImmigrationForm from "./ImmigrationForm";

const PatientImmigration = ({
  match,
  getEmployeeById,
  history,
  prePath,
  currentEmployee,
  getPatientImmigrationData,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [linkActive, setLinkActive] = React.useState(true);
  const [onlyOnce, setOnce] = React.useState(true);

  React.useEffect(() => {
    if (onlyOnce) {
      loadPage();
      removeAlert();
      loadAuthPage(history, prePath);
      setLoading(true);
      setFormLoading(true);
      getEmployeeById(match.params.user_id, "").then((res) => {
        setLoading(false);
        !res && notFound(history);
      });
      getPatientImmigrationData(match.params.user_id).then((res) => {
        setFormLoading(false);
      });
      setOnce(false);
    }
  });

  return loading || formLoading ? (
    <Spinner />
  ) : !linkActive ? (
    <InActiveLink />
  ) : (
    <>
      <Helmet>
        <title>
          Complete your immigration - {process.env.REACT_APP_APP_NAME}
        </title>
      </Helmet>
      <div className="page page-h">
        <div className="page-content z-index-10">
          <Container fluid>
            <Row className="px-5">
              <Col md="12">
                <Row>
                  <Col lg="4" sm="12" className="offset-lg-4">
                    <div className="text-center w-100 d-block mb-4">
                      <Img alt="logo" src={LogoSvg} className="w-50" />
                    </div>
                  </Col>
                  <Col md="12">
                    <ImmigrationForm currentEmployee={currentEmployee} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

PatientImmigration.propTypes = {
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  isLoginError: state.auth.login,
  currentEmployee: state.Employee.currentEmployee,
  prePath: state.auth.prePath,
  loading: state.EmployerProfile.loadingEmployer,
  localityParams: state.Employee.sortingParams,
});

export default connect(mapStateToProps, {
  getEmployeeById,
  getPatientImmigrationData,
})(PatientImmigration);
