import React from "react";
import { Switch, Route } from "react-router-dom";

import HomePage from "views/Front";
// import Login from "../Auth/Login";
import TermsAndConditions from "../TermsAndConditions";
// import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
// import ResetPassword from "views/Auth/ForgotPassword/ResetPassword";
import VerifyEmail from "views/Auth/VerifyEmail";
import NotFoundPage from "views/NotFound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PartnerLogin from "views/Auth/Employer/LoginComponent";
import EmployerLoginAdmin from "views/Auth/Employer/AdminLogin";
// import EmployerSignUp from "views/Auth/Employer/SignUp";
import EmployeeVerify from "views/Auth/Employee/EmployeeVerify";
import PatientRegistration from "views/Auth/Employee/PatientRegistration";
import EmployerForgotPassword from "views/Auth/Employer/ForgotPassword";
import EmployerResetPassword from "views/Auth/Employer/ResetPassword";
import EmployerSetPassword from "views/Auth/Employer/SetPassword";
import DynamicImport from "./DynamicImport";
import PatientImmigration from "views/Auth/Employee/PatientImmigration";

// const LabPanel = (props) => (
//   <DynamicImport load={() => import('views/Front/Lab/Layout')}>
//     {(Component) => Component === null
//       ? ""
//       : <Component {...props} />}
//   </DynamicImport>
// )

const EmployerPanel = (props) =>
  sessionStorage.organizationId ? (
    <DynamicImport load={() => import("views/Front/Employer/Layout")}>
      {(Component) => (Component === null ? "" : <Component {...props} />)}
    </DynamicImport>
  ) : (
    <DynamicImport load={() => import("views/Front/Employer")}>
      {(Component) => (Component === null ? "" : <Component {...props} />)}
    </DynamicImport>
  );

const LabPanel = (props) => (
  <DynamicImport load={() => import("views/Front/Lab/Layout")}>
    {(Component) => (Component === null ? "" : <Component {...props} />)}
  </DynamicImport>
);

const PhysicianPanel = (props) => (
  <DynamicImport load={() => import("views/Front/Physician/Layout")}>
    {(Component) => (Component === null ? "" : <Component {...props} />)}
  </DynamicImport>
);

const StaffPanel = (props) => (
  <DynamicImport load={() => import("views/Front/Staff/Layout")}>
    {(Component) => (Component === null ? "" : <Component {...props} />)}
  </DynamicImport>
);

const Routes = () => {
  return (
    <div>
      <Switch>
        <PublicRoute
          exact
          path="/verify/email/:token"
          restricted={true}
          component={VerifyEmail}
        />
        {/* Admin routes End  */}

        {/* Employee routes Start */}
        {/* <PublicRoute
          exact
          path="/register"
          restricted={true}
          component={EmployerSignUp}
        /> */}
        <PublicRoute
          exact
          path="/terms-and-conditions"
          restricted={true}
          component={TermsAndConditions}
        />

        <PublicRoute
          exact
          path="/employer-login"
          restricted={true}
          component={EmployerLoginAdmin}
        />
        <PublicRoute
          exact
          path="/"
          restricted={true}
          component={PartnerLogin}
        />
        <PublicRoute
          exact
          path="/login"
          restricted={true}
          component={PartnerLogin}
        />
        <PublicRoute
          exact
          path="/patient-update/:user_id"
          restricted={true}
          component={EmployeeVerify}
        />
        <PublicRoute
          exact
          path="/patient-immigration/:user_id"
          restricted={true}
          component={PatientImmigration}
        />
        <PublicRoute
          exact
          path="/forgotPassword"
          restricted={true}
          component={EmployerForgotPassword}
        />
        <PublicRoute
          exact
          path="/set-password"
          restricted={true}
          component={EmployerSetPassword}
        />
        <PublicRoute
          exact
          path="/resetPassword/:token"
          restricted={true}
          component={EmployerResetPassword}
        />
        <PublicRoute
          exact
          path="/patient-registration-form/:organizationID"
          restricted={false}
          component={PatientRegistration}
          publicPage={true}
        />
        {/* <PrivateRoute path="/lab*" component={LabPanel} /> */}
        <PrivateRoute path="/organization*" component={EmployerPanel} />
        <PrivateRoute path="/lab*" component={LabPanel} />
        <PrivateRoute path="/physician*" component={PhysicianPanel} />
        <PrivateRoute path="/staff*" component={StaffPanel} />
        <Route exact path="/*" name="Home" component={HomePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default Routes;
